<template>
  <div id="dashboard-widget-container">
    <b-tabs v-model="tabIndex" class="tabs--inner" pills lazy>
      <b-tab :title="$t('Dashboards')" active>
        <DashboardList />
      </b-tab>
      <b-tab :title="$t('Widgets')"><WidgetList /></b-tab>
      <b-tab :title="$t('Data Sources')"><DataSourceList /></b-tab>
      <b-tab :title="$t('Advanced Data Sources')"><DataSourceAdvancedList/></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import WidgetList from '@/views/pages/SystemSettings/DashboardWidgets/Tabs/WidgetList.vue'
import DataSourceList from '@/views/pages/SystemSettings/DashboardWidgets/Tabs/DataSourceList.vue'
import DataSourceAdvancedList from '@/views/pages/SystemSettings/DashboardWidgets/Tabs/DatasourcesAdvanced/DataSourceAdvancedList.vue'
import DashboardList from '@/views/pages/SystemSettings/DashboardWidgets/Tabs/DashboardList.vue'

export default {
  name: 'DashboardWidgetIndex',
  components: { WidgetList, DataSourceList, DashboardList, DataSourceAdvancedList },
  data: () => ({
    tabIndex: 0,
  }),
  watch: {
    tabIndex: {
      handler(newVal) {
        if (this.$route.query.search) {
          this.$router.push('/system-settings/dashboard-widget')
        }
      },
    },
  },
}
</script>
